import { Link } from 'gatsby'
import React from 'react'

const Card = ({ frontmatter }) => {
  return <div class="col-md-4 col-lg-3 featured-responsive">
    <div class={`featured-place-wrap ${frontmatter.featured && 'featured'}`}>
      <Link to={`/services/${frontmatter.path}`}>
        { frontmatter.featured && <div class="feature-badge">Featured</div>  }
        <img src={frontmatter.profileImage.childImageSharp.fixed.src} class="img-fluid" />
        <div class="featured-title-box">
          { parseInt(frontmatter.rating) > 0 && <div className="featured-rating">{ frontmatter.rating }</div> }
          <h6>{frontmatter.title}</h6>
          <p>{ frontmatter.location }</p>
        </div>
      </Link>
    </div>
  </div>
}

export default Card
