import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image'

import Layout from '../layouts/home'
import Card from '../components/card'
import SEO from '../components/seo'
import Meta, { keywords } from '../components/meta'

function keywordsMaker(category, location) {
  return keywords[category].map(c => `${location} ${c}`)
}

export default function ListTemplate({ location, data }) {
  const splits = location.pathname.split('/');

  const renderList = () => {
    return data.allMarkdownRemark.edges.map(e => <Card frontmatter={e.node.frontmatter} key={e.node.frontmatter.path} />)
  }
  const currentCategoryLowerCase = splits[2];
  const currentCategory = currentCategoryLowerCase.titleize();
  const currentMarket = splits[3].capitalize();

  return (
    <Layout>
      <SEO
        title={`Listing all ${currentCategory} in ${currentMarket}`}
        keywords={keywordsMaker(currentCategoryLowerCase, currentMarket)}
        description={`${Meta[splits[2]].replace('in Nepal', 'in '+ currentMarket)}`}
        meta={[
          {
            property: 'og:url',
            content: `http://www.ceremonynepal.com/services/${currentCategoryLowerCase}/${currentMarket.toLowerCase()}`
          },
          {
            property: 'og:image',
            content: `http://www.ceremonynepal.com/images/cover-images/ceremonynepal-${currentCategoryLowerCase}.jpg`
          }
        ]}
      />
      <section class="reserve-block">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <h1>{ `Listing ${currentCategory} in ${currentMarket}` }</h1>
              <p class="reserve-description">
                <a class="btn btn-sm btn-badge" href={`/services/${splits[2]}`}>◀ Back to all {currentCategory}</a>
              </p>
            </div>
          </div>
        </div>
      </section>
      <div class="light-bg">
        <div class="container">
          <div class="row detail-options-wrap">
            { data.allMarkdownRemark && renderList() }
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ListByCategoriesAndMarket($Market: String, $Category: String) {
    allMarkdownRemark(filter: { frontmatter: { markets: { in: [$Market] }, categories: { in: [$Category] } }}) {
      edges {
        node {
          frontmatter {
            path
            title
            location
            featured
            rating
            profileImage {
              childImageSharp {
                fixed(width: 420, height: 340) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`
